import React from "react";
import heroprom from "../assets/promhero.jpg";

const HeroSection: React.FC = () => {
    return (
        <section className="relative md:h-screen w-full overflow-hidden text-white font-sans">
            <div className="inset-0 overflow-hidden">
                <img src={heroprom} className="ken-burns-effect" />
            </div>
        </section>
    );
};

export default HeroSection;
