import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { venueUrl } from '../common/consts'

import artist1 from '../assets/tmp/artist1.jpg'
import artist2 from '../assets/tmp/artist2.jpg'
import artist3 from '../assets/tmp/artist3.jpg'

const VenueInfo: React.FC = () => {
    return (
        <section className="py-12 px-4 md:px-16">
            <div className="max-w-4xl mx-auto text-center">
                <p className="text-4xl md:text-5xl font-semibold mb-6 font-serif">
                    Venue
                </p>
            </div>

            {/* Carousel Wrapper with rounded corners */}
            <div className="max-w-4xl mx-auto rounded-xl overflow-hidden">
                <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    showThumbs={false}
                    emulateTouch={true}
                    swipeable={true}
                    showIndicators={true}
                    showArrows={false}
                    showStatus={false}
                >
                    <div className="h-48 md:h-96">
                        <img
                            src={artist1}
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="h-48 md:h-96">
                        <img
                            src={artist2}
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="h-48 md:h-96">
                        <img
                            src={artist3}
                            className="w-full h-full object-cover"
                        />
                    </div>
                </Carousel>
            </div>

            {/* Responsive Google Maps Iframe */}
            <div className="mx-auto my-8 max-w-4xl">
                <div
                    className="relative rounded-xl shadow-xl bg-black/20 overflow-hidden"
                    style={{ paddingBottom: '56.25%', height: 0 }}
                >
                    <iframe
                        src={venueUrl}
                        className="absolute top-0 left-0 w-full h-full"
                        width="425"
                        height="350"
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                </div>
            </div>
        </section>
    )
}

export default VenueInfo
