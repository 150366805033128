import React from 'react'
import { Calendar3, GeoAlt } from 'react-bootstrap-icons'

export interface EventDetail {
    icon: React.ReactNode
    title: string
    subtitle: string
}

const eventDetails: EventDetail[] = [
    {
        icon: <Calendar3 size={48} />,
        title: 'Saturday, March 8',
        subtitle: '9:00 PM - Late'
    },
    {
        icon: <GeoAlt size={48} />,
        title: 'S Club',
        subtitle: 'Shibuya, Tokyo'
    }
]


const EventDetailsSection: React.FC = () => {
    return (
        <section className="pb-12 px-8 md:px-16">
            <div className="max-w-xl mx-auto md:flex justify-evenly md:space-x-8 md:space-y-0 space-y-6">
                {eventDetails.map((detail, index) => (
                    <div key={index} className="flex items-center space-x-4">
                        <div className="h-16 w-16 flex items-center justify-center">
                            {detail.icon}
                        </div>
                        <div className="flex flex-col h-20 justify-center">
                            <p className="text-xl font-bold">{detail.title}</p>
                            <p className="text-sm">{detail.subtitle}</p>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default EventDetailsSection
