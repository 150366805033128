export const goToInstagram = (): void => {
    const username = 'ragtagsocials'
    const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent)
    const isAndroid = navigator.userAgent.includes('Android')
    const accountUrl = `https://www.instagram.com/${username}/`

    if (isIOS) {
        window.location.href = `instagram://user?username=${username}`
    } else if (isAndroid) {
        window.location.href = `intent://instagram.com/_u/${username}/#Intent;package=com.instagram.android;scheme=https;end`
    } else {
        window.open(accountUrl, '_blank')
    }
}