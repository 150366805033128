import React, { useState } from 'react'

export interface FAQ {
    question: string
    answer: string
}

interface FaqItemProps {
    question: string
    answer: string
}

const faqs: FAQ[] = [
    {
        question: 'What is the dress code?',
        answer: 'Dress in your most refined yet relaxed urban style – chic and comfortable.'
    },
    {
        question: 'Can I bring a guest?',
        answer: 'Absolutely. At Ragtag, we believe the finer things are best enjoyed with company.'
    },
    {
        question: 'How do I get tickets?',
        answer: 'Tickets will be available soon. Secure your spot for an unforgettable night.'
    },
    {
        question: 'Is there an age limit?',
        answer: 'This event is open to guests 18 and older.'
    }
]

const FaqItem: React.FC<FaqItemProps> = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    return (
        <div className="rounded-lg p-6 border border-white-800 bg-black/20">
            <div
                className="cursor-pointer flex justify-between items-center"
                onClick={() => setIsOpen(!isOpen)}
            >
                <h3 className="text-xl font-semibold mb-0 font-serif">
                    {question}
                </h3>
                <span className="text-2xl font-bold">
                    {isOpen ? '−' : '+'}
                </span>
            </div>
            <div
                className={`overflow-hidden transition-all duration-300 ease-in-out ${isOpen ? 'max-h-96 mt-4' : 'max-h-0'
                    }`}
            >
                <p>{answer}</p>
            </div>
        </div>
    )
}

const FAQSection: React.FC = () => {
    return (
        <section className="py-12 px-4 md:px-16">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl md:text-5xl font-semibold mb-8 font-serif text-center">
                    FAQs
                </h1>
                <div className="space-y-6">
                    {faqs.map((faq, index) => (
                        <FaqItem key={index} question={faq.question} answer={faq.answer} />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default FAQSection
