import React, { ReactElement } from 'react'

export const Footer = (): ReactElement => {
  return (
    <footer
      className="relative -mt-32 h-32 text-xs text-white flex items-end justify-center"
      style={{
        // Adjust the gradient stops to your preference
        background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(213, 86, 86) 100%)'
      }}
    >
      <div className="container mx-auto px-4 py-4 mb-2 text-center">
        <p className="m-0">© 2025 Ragtag Socials</p>
        <p className="m-0">Made with ❤️ in Tokyo.</p>
      </div>
    </footer>
  )
}
