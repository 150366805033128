import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './output.css'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes
} from 'react-router-dom'
import ScrollToTop from './utils/scrollToTop'
import LandingPageV2 from './pages/LandingPageV2'
// import LandingPage from './pages/LandingPage'
// import ErrorPage from './pages/ErrorPage'
// import Invitation from './pages/Invitation'
// import InvitationShow from './pages/InvitationShow'
// import InvitationDetails from './pages/InvitationDetails'
// import RemoveMyInvitation from './pages/RemoveMyInvitation'
// import ThankYouRagtagXXX from './pages/ThankYou'
import { Footer } from './common/Footer'
import InvitationOneStep from './pages/InvitationOneStep'
import RagtagLandingPage from './pages/RagtagLandingPage'

// import LandingPageV3 from './pages/LandingPageV3'
// import InputEmail from './pages/InputEmail'

// const router = createBrowserRouter([
//   {
//     path: '/',
//     element: <LandingPage/>,
//     errorElement: <ErrorPage />
//   },
//   {
//     path: 'invitation/:invitationKey',
//     element: <Invitation/>
//   },
//   {
//     path: 'your-invitation/',
//     element: <InvitationShow/>
//   },
//   {
//     path: 'qr-scan/:invitationKey',
//     element: <InvitationDetails/>
//   },
//   {
//     path: '*',
//     element: <ErrorPage/>
//   }
// ])

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/*
          <Route path='m/' element={<InputEmail/>} />
          <Route path='your-invitation/' element={ <InvitationShow/> } />
          <Route path='qr-scan/:invitationKey' element={ <InvitationDetails/>} />
          <Route path='remove-my-invitation' element={ <RemoveMyInvitation/>} /> */}
        {/* <Route path='thank-you' element={<ThankYouRagtagXXX />} /> */}
        <Route path="/" element={<LandingPageV2 />} />
        <Route path="/prom" element={<RagtagLandingPage />} />
        <Route path='links/demo/:invitationKey' element={<InvitationOneStep />} />
        <Route
          path='*'
          element={<Navigate to="/" replace />}
        // element={<ErrorPage />}
        />
      </Routes>
    </BrowserRouter>
    <Footer />
  </React.StrictMode>
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
