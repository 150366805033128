import React, { ReactElement } from 'react'
import hero from '../assets/herovideo.webm'
import { Button, Container } from 'react-bootstrap'
// import { Container, Row } from 'react-bootstrap'

// import { EventInfo } from '../common/EventInfo'
// import { TextHeader } from '../common/TextHeader'

function LandingPageV2(): ReactElement {
  const handleClick = (): void => {
    const username = 'ragtagsocials'

    // Check if the device is iOS or Android
    const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent)
    const isAndroid = navigator.userAgent.includes('Android')

    const accountUrl = `https://www.instagram.com/${username}/`

    if (isIOS) {
      window.location.href = `instagram://user?username=${username}`
    } else if (isAndroid) {
      window.location.href = `intent://instagram.com/_u/${username}/#Intent;package=com.instagram.android;scheme=https;end`
    } else {
      // Redirect to Instagram website for other platforms
      window.open(accountUrl, '_blank')
    }
  }
  return (<>
    <Container fluid="md">
      <header className="video-header">
        <video src={hero} autoPlay loop muted></video>
        <div className="viewport-header">
          <div className="ragtag">
            <Button
              size="lg"
              style={{ background: 'linear-gradient(45deg, rgb(255 148 161), #cd2f96)', borderRadius: "16px", padding: "20px" }}
              onClick={() => { handleClick() }}>
              RAGTAG
            </Button>
          </div>
        </div>
      </header >
    </Container>

    {/* <main>
      <Container fluid="md">
        <Container>
          <TextHeader
            header='Shibuya Heaven and Hell is comming soon'
            subheader=''
          />
          <EventInfo showPrice={false} />
          <br /><br />
          <Row>
            <p>
              <a href="https://www.instagram.com/ragtagsocials/" target="_blank" rel="noreferrer"> @ragtagsocials</a>
            </p>
          </Row>
        </Container>
      </Container>
    </main> */}
  </>)
}

export default LandingPageV2
