import React, { useState, useRef, useEffect } from 'react'

import artist1 from '../assets/tmp/artist1.jpg'
import artist2 from '../assets/tmp/artist2.jpg'
import artist3 from '../assets/tmp/artist3.jpg'
import artist4 from '../assets/tmp/artist4.jpg'
import artist5 from '../assets/tmp/artist5.jpg'

interface Artist {
    name: string
    image?: string | null
    isLiveMusic?: boolean
}

const artists: Artist[] = [
    { name: 'The Echo Spectrums', image: artist1, isLiveMusic: false },
    { name: 'Astral Pulse', image: artist2, isLiveMusic: true },
    { name: 'Neon Haze', image: artist3, isLiveMusic: false },
    { name: 'Cosmic Drift', image: artist4, isLiveMusic: true },
    { name: 'Eclipse Spetrum DJ', image: artist5, isLiveMusic: false },
    { name: 'The Echo Spectrums', image: null, isLiveMusic: true },
    { name: 'Astral Pulse', image: null, isLiveMusic: false },
    { name: 'Eclipse Spetrum DJ', image: artist5, isLiveMusic: false },
    { name: 'The Echo Spectrums', image: null, isLiveMusic: true },
    { name: 'Astral Pulse', image: null, isLiveMusic: false },
    { name: 'Eclipse Spetrum DJ', image: artist5, isLiveMusic: false },
    { name: 'The Echo Spectrums', image: null, isLiveMusic: true },
    { name: 'Astral Pulse', image: null, isLiveMusic: false },
]

const ArtistCard = ({ artist }: { artist: Artist }) => (
    <div className="rounded-xl shadow-xl bg-black/20 overflow-hidden">
        {artist.image ? (
            <div className="relative">
                <img
                    src={artist.image}
                    alt={artist.name}
                    className="w-full h-48 object-cover"
                />
                <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-[#d55656] to-transparent p-4">
                    {/* Container for name (left) and badge (right) */}
                    <div className="flex items-center justify-between">
                        <h3 className="text-white text-xl">
                            {artist.name}
                        </h3>
                        {artist.isLiveMusic && (
                            <span className="inline-block bg-green-600 text-white text-xs font-bold px-2 py-1 rounded-xl">
                                Live Music
                            </span>
                        )}
                    </div>
                </div>
            </div>
        ) : (
            <div className="p-4">
                {/* Same pattern, name on left, badge on right */}
                <div className="flex items-center justify-between">
                    <h3 className="text-white text-xl">
                        {artist.name}
                    </h3>
                    {artist.isLiveMusic && (
                        <span className="inline-block bg-green-600 text-white text-xs font-bold px-2 py-1 rounded-xl">
                            Live&nbsp;Music
                        </span>
                    )}
                </div>
            </div>
        )}
    </div>
)


const FeaturedArtistsSection: React.FC = () => {
    const [showAll, setShowAll] = useState(false)
    const extraArtistsRef = useRef<HTMLDivElement>(null)
    const [extraHeight, setExtraHeight] = useState(0)

    const visibleArtists = artists.slice(0, 6)
    const extraArtists = artists.slice(6)

    useEffect(() => {
        if (extraArtistsRef.current) {
            setExtraHeight(extraArtistsRef.current.scrollHeight)
        }
    }, [showAll, extraArtists.length])

    return (
        <section className="py-12 px-4 md:px-16">
            <div className="max-w-4xl mx-auto">
                <p className="text-4xl md:text-5xl font-semibold mb-8 font-serif text-center">
                    Featured Artists
                </p>
                {/* Always visible artists */}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                    {visibleArtists.map((artist, index) => (
                        <ArtistCard key={index} artist={artist} />
                    ))}
                </div>

                {/* Extra artists that animate open/close */}
                {extraArtists.length > 0 && (
                    <div
                        ref={extraArtistsRef}
                        style={{
                            maxHeight: showAll ? extraHeight : 0,
                            overflow: 'hidden',
                            transition: 'max-height 0.5s ease'
                        }}
                    >
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-6">
                            {extraArtists.map((artist, index) => (
                                <ArtistCard key={index + 5} artist={artist} />
                            ))}
                        </div>
                    </div>
                )}

                {/* Toggle Button */}
                {extraArtists.length > 0 && !showAll && (
                    <div className="mt-4 flex justify-center">
                        <button
                            onClick={() => setShowAll(!showAll)}
                            className="px-4 py-2 bg-black/20 text-white rounded-xl hover:bg-yellow-700 transition"
                        >
                            See all artist
                        </button>
                    </div>
                )}
            </div>
        </section>
    )
}

export default FeaturedArtistsSection
