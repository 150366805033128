import React from 'react'

import HeroSection from '../components/HeroSection'
import EventDetailsSection from '../components/EventDetailsSection'
import FAQSection from '../components/FAQSection'
import FeaturedArtistsSection from '../components/FeaturedArtistsSection'
import { goToInstagram } from '../hooks/goToInstagram'

import VenueInfo from '../components/VenueInfo'


const RagtagPromLandingPage: React.FC = () => {
    return (
        <>
            {/* Inline keyframes for a subtle, breathing gradient animation */}
            <style>
                {`
                    @keyframes gradientBreath {
                        0% {
                            background-position: 0% 50%;
                        }
                        50% {
                            background-position: 100% 50%;
                        }
                        100% {
                            background-position: 0% 50%;
                        }
                    }
                `}
            </style>

            <HeroSection />

            {/* Main content container with animated gradient background */}
            <div
                className="font-sans text-white pb-32"
                style={{
                    backgroundImage: `linear-gradient(
                        105deg,
                        #870000 0%,
                        #190A05 17%,
                        hsl(20deg 53% 46%) 82%,
                        hsl(22deg 51% 41%) 92%,
                        hsl(27deg 43% 30%) 100%
                    )`,
                    backgroundSize: '200% 200%',
                    animation: 'gradientBreath 10s ease-in-out infinite'
                }}
            >
                {/* --- ABOUT THE EVENT (remains inline) --- */}
                <section className="py-12 px-4 md:px-16">
                    <div className="max-w-4xl mx-auto text-center">
                        <p className="text-4xl md:text-5xl font-semibold mb-6 font-serif">
                            Ragtag Prom Night
                        </p>
                        <p className="text-lg leading-relaxed">
                            Step into an evening where art meets soul in Tokyo’s vibrant heart. Let the smooth rhythms,
                            striking visuals, and subtle luxury transport you into a world of refined revelry. Welcome to
                            Ragtag – a celebration of style, sound, and unforgettable moments.
                        </p>
                    </div>
                </section>

                <EventDetailsSection />

                <FeaturedArtistsSection />

                <VenueInfo />



                <section className="py-6 px-4 md:px-16">
                    <div className="max-w-3xl mx-auto text-center">
                        <button
                            onClick={goToInstagram}
                            className="
                                rounded-xl
                                py-4
                                px-4
                                font-semibold
                                text-white
                                shadow
                                bg-gradient-to-l
                                from-orange-400
                                to-[#cd2f96]
                                hover:opacity-90
                            "
                        >
                            Visit Ragtag on Instagram
                        </button>
                    </div>
                </section>

                <FAQSection />
            </div>
        </>
    )
}

export default RagtagPromLandingPage
